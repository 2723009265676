/* for images inside dangerous HTML content */
.parse-html img {
  max-width: 100%; /* Ensure images don't exceed the width of their container */
  height: auto; /* Maintain the aspect ratio of the images */
  display: block; /* Prevent whitespace below inline images */
  margin: 10px auto; /* Center images */
}

.ql-video {
  display: block;
  max-width: 100%;
  width: 100%;
  height: 200px;
}

@media (min-width: 576px) {
  .ql-video {
    height: 300px;
  }
}

@media (min-width: 768px) {
  .ql-video {
    height: 400px;
  }
}

@media (min-width: 992px) {
  .ql-video {
    height: 500px;
  }
}
